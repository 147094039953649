.download-button-group
  display: flex
  flex-direction: row

  > a
    display: block
    height: 100%

    > img
      height: 100%
